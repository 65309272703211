import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, delay, map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EnvironmentUrlService } from './environment-url.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { RepositoryService } from './repository.service';
import { User } from 'src/app/modules/_models/common/User';
import { ReturnStatement } from '@angular/compiler';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  constructor(
    private http: HttpClient,
    private envUrl: EnvironmentUrlService,
    private repo: RepositoryService,
    private router: Router,
    private notify: NotificationService
  ) {}

  login(model: any) {
    this.repo.getDataByPost('api/auth/login', model).subscribe(
      (res) => {
        const usertoken = res;
        if (usertoken) {
          localStorage.setItem('outhtoken', usertoken.token);
          this.decodedToken = this.jwtHelper.decodeToken(usertoken.token);
          // store user details and jwt token in local storage to keep user loggedin between page refreshes
          localStorage.setItem('currentUser', this.decodedToken);
        } else {
          this.router.navigate(['/noaccess']);
        }
      },
      (error) => {
        if (error.status == 401) {
          this.notify.showError(
            'You have not registered to this application !!',
            'Message !!'
          );
        }
        this.router.navigate(['/noaccess']);
      }
    );
  }
  loggedIn() {
    const token = localStorage.getItem('outhtoken');
    return !this.jwtHelper.isTokenExpired(token);
  }

  roleMatch(allowedRoles) {
    let isMatch = false;
    const userRoles = this.decodedToken?.role as string;
    allowedRoles.forEach((element) => {
      if (userRoles == element) {
        isMatch = true;
        return;
      }
    });
    return isMatch;
  }
}
