import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptorProvider } from './shared/services/error.interceptor';
import { AvatarModule } from 'ngx-avatar';
// import { HasRoleDirective } from './modules/_directives/hasRole.directive';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ExcelService } from './shared/services/excel.service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
export function tokenGetter() {
  //console.log(localStorage.getItem('outhtoken'));
  return localStorage.getItem('outhtoken');
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule,

    ReactiveFormsModule, //Add if needed
    FormsModule, //Add if needed
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        //Prod
       // allowedDomains: ['cmsapi.clarios.com'],
       // disallowedRoutes: ['cmsapi.clarios.com/api/auth'],
        //QA
        // allowedDomains: ['cmsapi-qa.clarios.com'],
        // disallowedRoutes: ['cmsapi-qa.clarios.com/api/auth'],
        //DEV
         allowedDomains: ["cmsapi-dev.clarios.com"],
         disallowedRoutes: ["cmsapi-dev.clarios.com/api/auth"],
        // allowedDomains: ['localhost:44322'],
        // disallowedRoutes: ['localhost:44322/api/auth'],
      },
    }),
    MsalModule.forRoot(
      {
        auth: {
          //Prod
         // clientId: 'd8fb3e2b-a91e-4f6b-9d86-aacf914221ec',
         // authority:
          //  'https://login.microsoftonline.com/74b72ba8-5684-402c-98da-e38799398d7d',
          // redirectUri: 'https://cms.clarios.com',
          //QA
          // clientId: '3444fe78-5df6-4fab-8357-3d0df44ec334',
          // authority:
          //   'https://login.microsoftonline.com/74b72ba8-5684-402c-98da-e38799398d7d',
          // redirectUri: 'https://cms-qa.clarios.com',
          //DEV
           clientId:'1abf6344-67cd-4188-b532-d246bf7d21b7',
           authority: 'https://login.microsoftonline.com/74b72ba8-5684-402c-98da-e38799398d7d',
           redirectUri: 'https://cms-dev.clarios.com',
          // clientId: 'ff2689b2-ee77-448e-a0ec-5b719be9c988',
          // authority:
          //   'https://login.microsoftonline.com/74b72ba8-5684-402c-98da-e38799398d7d',
          // redirectUri: 'https://localhost:4200/',
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // set to true for IE 11
        },
      },
      {
        popUp: !isIE,
        consentScopes: ['user.read', 'openid', 'profile'],
        unprotectedResources: [],
        protectedResourceMap: [
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ],
        extraQueryParameters: {},
      }
    ),
    AvatarModule,
  ],
  exports: [SharedModule, ToastrModule, CoreModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    ErrorInterceptorProvider,
    ExcelService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
