import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../material.module';
// import { MaterialExtensionsModule } from '@ng-matero/extensions';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { RouterTabs } from './router-tab/router-tabs.directive';
import { RouterTab } from './router-tab/router-tab.directive';
import { LogoutComponent } from './logout/logout.component';
const THIRD_MODULES = [
  MaterialModule,
  // MaterialExtensionsModule,
  FlexLayoutModule,
];

@NgModule({
  imports: [CommonModule, RouterModule, FlexLayoutModule, ...THIRD_MODULES],
  declarations: [
    PageNotFoundComponent,
    RouterTabs,
    RouterTab,
    NoAccessComponent,
    LogoutComponent,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    PageNotFoundComponent,
    RouterTabs,
    RouterTab,

    ...THIRD_MODULES,
  ],
})
export class SharedModule {}
