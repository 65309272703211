import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentUrlService {
  public urlAddress: string = environment.urlAddress;

  PowerBIKeys = {
    Dev: {
      wrkSpcId: '99f35b3d-6d0e-4815-b6c6-8d7bd38e3dcd',
      reportId: 'da9dd29d-591a-4dc4-bb42-0f204b932b7f',
      groupId: '99f35b3d-6d0e-4815-b6c6-8d7bd38e3dcd',
    },
    QA: {
      wrkSpcId: 'fc3dda77-21b1-4b7a-8679-6822d78590a6',
      reportId: '4c177320-41cf-4039-b12e-bfb46bf6a6c3',
      groupId: 'fc3dda77-21b1-4b7a-8679-6822d78590a6',
    },
    Prod: {
      wrkSpcId: '1acfa25c-26f8-4475-982a-5bfdd664c440',
      reportId: '9f2d830a-688a-4c40-a900-9500fd5742e2',
      groupId: '1acfa25c-26f8-4475-982a-5bfdd664c440',
    },
  };

  constructor() {}
}
