import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HasNotRoleDirective } from './_directives/hasNotRole.directive';
import { HasRoleDirective } from './_directives/hasRole.directive';

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [HasNotRoleDirective, HasRoleDirective],
  declarations: [HasNotRoleDirective, HasRoleDirective],
})
export class CoreModule {}
