import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PreloaderService } from './core/preloader.service';

import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { AuthService } from './shared/services/auth.service';
import { UserAuthenticateModel } from './modules/_models/common/UserAuthenticateModel';
import { StorageService } from './shared/services/storage.service';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';

export const Auth_NameKEY = 'LoggedUsername';
export const Auth_UserNameKEY = 'username';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit, AfterViewInit {
  title = 'CMSApp 2.0';
  loggedIn = false;
  isIframe = false;

 
  userAuthModel: UserAuthenticateModel;

  constructor(
    private preloader: PreloaderService,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private appauthService: AuthService,
    private store: StorageService,
    private router: Router) { }

  ngOnInit() {
    
    this.checkAccount();
    if (!this.loggedIn) {
      this.authService.loginRedirect();
    }
    else {
      this.isIframe = window !== window.parent && !window.opener;

      this.broadcastService.subscribe('msal:loginSuccess', () => {
        this.checkAccount();
      });

      this.authService.handleRedirectCallback((authError, response) => {
        if (authError) {
          console.error('Redirect Error: ', authError.errorMessage);
          return;
        }
      });

      this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      }, {
        correlationId: CryptoUtils.createNewGuid(),
        piiLoggingEnabled: false
      }));
    }
  }

  cmsLogin() {
    this.appauthService.login(this.userAuthModel);
    delay(5000);  //To load all data for authorization
  }

  checkAccount() {
    this.loggedIn = !!this.authService.getAccount();
    if (this.loggedIn) {
      var profile = this.authService.getAccount();
      this.setPlant(Auth_NameKEY ,profile.name);
      this.setPlant(Auth_UserNameKEY ,profile.userName);
      this.userAuthModel = {
        id: 0,
        ssoauthtoken: '',
        username: profile.userName
      };
      this.cmsLogin();
    }
  }

  logout() {
    this.store.remove(Auth_NameKEY);
    this.store.remove(Auth_UserNameKEY);
    this.authService.logout();
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }
  setPlant(key:string ,value: string) {
    this.store.remove(key);
    this.store.set(key, value);
  }
}
