import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { NoAccessComponent } from './shared/no-access/no-access.component';
import { UserIdentificationComponent } from './core/userIdentification/userIdentification.component';

const routes: Routes = [
  // {
  //   path: 'UserIdentification',
  //   component: UserIdentificationComponent,
  // },
  {
    path: '',
    loadChildren: () =>
      import('./modules/modules.module').then((m) => m.ModulesModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'noaccess',
    component: NoAccessComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
